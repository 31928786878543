import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Form from "../components/Form";

function SignupLayout({ pageContext }) {
  console.warn("SignupLayout pageContext", pageContext);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
      <Form
        owner={pageContext?.attributes?.field_owner}
        body={pageContext?.attributes?.body?.processed}
        title={pageContext?.attributes?.title}
      />
    </GoogleReCaptchaProvider>
  );
}

export default SignupLayout;
